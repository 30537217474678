import { HStack, VStack, StackProps, Icon, Text, Link } from "@chakra-ui/react";
import { Feature, Point } from "geojson";
import React, { useMemo } from "react";
import { FaMapMarker, FaMousePointer } from "react-icons/fa";
import { intlFormat } from "date-fns";

import { IncidentProperties } from "types";
import { MiddotDivider } from "index";

export type DashboardIncidentItemSummaryStrings = Partial<{
  /** Example: "Type" -- type of incident*/
  typeCopy: string;
}>;

const defaultStrings: Required<DashboardIncidentItemSummaryStrings> = {
  typeCopy: "Type",
};

const containerProps: StackProps = {
  alignItems: "flex-start",
  px: 3,
  py: 4,
  fontSize: "sm",
  width: "100%",
};

export type DashboardIncidentItemProps = {
  /**
   * Incident Feature to display
   */
  incident: Feature<Point | null, IncidentProperties>;
  /**
   * Object of content strings and interpolators
   */
  strings?: DashboardIncidentItemSummaryStrings;
  /**
   * Display only title, date and location information
   */
  showSummaryOnly?: boolean;
  /**
     A string descibing the types of volence logged for the incident
   */
  violenceTypes?: string;
  /**
     A string descibing the types of volence logged for the incident
   */
  summaryText?: string;
  /**
     The maximum number of lines of summary content to display
   */
  maxSummaryTextLineCount?: number;
} & StackProps;

export const DashboardIncidentItem = ({
  strings,
  incident,
  showSummaryOnly = false,
  violenceTypes = '',
  summaryText = '',
  maxSummaryTextLineCount = 5,
  ...rest
}: DashboardIncidentItemProps) => {
  const safeStrings: Required<DashboardIncidentItemSummaryStrings> = {
    ...defaultStrings,
    ...strings,
  };

  const isPhysical = incident.geometry !== null;

  const icon = isPhysical ? FaMapMarker : FaMousePointer;

  const reportDate = useMemo(
    () =>
      intlFormat(new Date(incident.properties.start_date), {
        month: "short",
        day: "numeric",
        year: "numeric",
      }),
    [incident.properties.start_date]
  );

  const violenceTypesComponent = violenceTypes && (
    <HStack alignSelf="flex-start" align="baseline">
        <Text>{safeStrings.typeCopy}: </Text>
        <Text>{violenceTypes}</Text>
    </HStack>
  )

  return (
    <HStack {...containerProps} {...rest}>
      {!showSummaryOnly && <Icon as={icon} color="cyan.500" mt={1} />}
      <VStack alignItems="stretch" flex="1" spacing={4}>
        <VStack alignItems="flex-start" spacing={0.5}>
          <Text fontWeight="bold">
            <Link href={`/incidents/${incident.id}`}>
              {incident.properties.title}
            </Link>
          </Text>
          <HStack divider={<MiddotDivider />} color="gray.600" flexWrap="wrap">
            <Text whiteSpace="nowrap">{reportDate}</Text>
            {isPhysical && <Text>{incident.properties.time_of_day}</Text>}
            <Text>{incident.properties.location_name}</Text>
          </HStack>
        </VStack>
        {!showSummaryOnly && (
          <VStack alignSelf="stretch" spacing={1}>
            {violenceTypesComponent}
            <Text color="gray.600" noOfLines={maxSummaryTextLineCount}>
              {summaryText}
            </Text>
          </VStack>
        )}
      </VStack>
    </HStack>
  );
};
