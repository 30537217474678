import { Box, BoxProps, Flex } from "@chakra-ui/react";
import React from "react";

export type MiddotDividerProps = BoxProps;

export const MiddotDivider = (props: MiddotDividerProps) => (
  <Box {...props} borderColor="transparent">
    &middot;
  </Box>
);
