import { variantOutline } from "./input";

export default {
  baseStyle: {
    icon: {
      color: "gray.300",
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: "lg",
      },
    },
  },
  variants: {
    outline: variantOutline,
  },
};
