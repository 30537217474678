import {
  Box,
  Checkbox,
  CheckboxProps,
  forwardRef,
  NumberInput,
  Input,
  VStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  StackProps,
  TextProps,
  Text,
  NumberInputProps,
  InputProps,
} from "@chakra-ui/react";
import React, { ChangeEvent, useCallback } from "react";

export type CheckboxButtonProps = CheckboxProps;

export const CheckboxButton = forwardRef<CheckboxButtonProps, "input">(
  ({ colorScheme = "gray", fontSize = "lg", ...rest }, ref) => {
    return (
      <Box
        fontSize={fontSize}
        borderRadius="md"
        border="2px solid"
        borderColor="gray.200"
        px={4}
        py={2}
        width="100%"
      >
        <Checkbox width="100%" colorScheme={colorScheme} ref={ref} {...rest} />
      </Box>
    );
  }
);

const outerContainerProps: StackProps = {
  alignItems: "stretch",
};

const secondaryContainerProps: StackProps = {
  borderRadius: "base",
  bg: "gray.50",
  px: 4,
  py: 2,
  alignItems: "stretch",
};

const secondaryLabelProps: TextProps = {
  fontSize: "md",
};

export type MultichoiceCheckboxChangeValue = {
  checked: boolean | undefined;
  value?: string | number;
};

type BareMultichoiceCheckboxButtonProps = Omit<
  CheckboxButtonProps,
  "onChange"
> & {
  onChange: (value: MultichoiceCheckboxChangeValue) => void;
};

export type MultichoiceCheckboxButtonProps =
  | (BareMultichoiceCheckboxButtonProps & {
      choiceType: "number";
      secondaryLabel: string;
      secondaryFieldProps: NumberInputProps;
    })
  | (BareMultichoiceCheckboxButtonProps & {
      choiceType: "text";
      secondaryLabel: string;
      secondaryFieldProps: InputProps;
    })
  | (BareMultichoiceCheckboxButtonProps & {
      choiceType: "boolean";
    });

export const MultichoiceCheckboxButton = forwardRef<
  MultichoiceCheckboxButtonProps,
  "input"
>((props, ref) => {
  const {
    colorScheme = "gray",
    fontSize = "lg",
    onChange,
    isChecked,
    choiceType,
    ...rest
  } = props;
  const showTextInput = isChecked && choiceType === "text";
  const showNumberInput = isChecked && choiceType === "number";
  const showSecondaryInput = showTextInput || showNumberInput;

  const onChangeCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (choiceType === "boolean") {
        onChange &&
          onChange({
            checked: e.target.checked,
          });
      } else {
        onChange &&
          onChange({
            checked: e.target.checked,
            value: props.secondaryFieldProps.value as string | number,
          });
      }
    },
    [onChange]
  );

  const onChangeSecondaryText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange &&
        onChange({
          checked: isChecked,
          value: e.target.value,
        });
    },
    [isChecked]
  );

  const onChangeSecondaryNumber = useCallback(
    (_: string, valueAsNumber: number) => {
      onChange &&
        onChange({
          checked: isChecked,
          value: valueAsNumber,
        });
    },
    [isChecked]
  );

  return (
    <VStack {...outerContainerProps}>
      <Box
        fontSize={fontSize}
        borderRadius="md"
        border="2px solid"
        borderColor="gray.200"
        px={4}
        py={2}
        width="100%"
      >
        <Checkbox
          width="100%"
          colorScheme={colorScheme}
          isChecked={isChecked}
          {...rest}
          onChange={onChangeCheckbox}
          ref={ref}
        />
      </Box>
      {showSecondaryInput && (
        <VStack {...secondaryContainerProps}>
          {!!props.secondaryLabel && (
            <Text {...secondaryLabelProps}>{props.secondaryLabel}</Text>
          )}
          {showTextInput && (
            <Input
              {...props.secondaryFieldProps}
              onChange={onChangeSecondaryText}
            />
          )}
          {showNumberInput && (
            <NumberInput
              {...props.secondaryFieldProps}
              onChange={onChangeSecondaryNumber}
              width="100%"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        </VStack>
      )}
    </VStack>
  );
});
