import { StackProps, VStack, Text } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState, forwardRef } from "react";
import InteractiveMap, {
  Layer,
  MapEvent,
  NavigationControl,
  Source,
} from "react-map-gl";
import { debounce } from "lodash";
import { Feature, FeatureCollection, Point } from "geojson";

import { IncidentProperties, ValueToString } from "types";
import { DashboardIncidentItem } from "index";

import type { CirclePaint } from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

const navControlStyle = {
  right: 10,
  top: 10,
};

const popupCSSOverride = `

.mapboxgl-popup-content {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.mapboxgl-popup-tip {
  display: none;
}
`;

const popupPropsFactory = (offsetCenter: {
  x: number;
  y: number;
}): StackProps => ({
  bg: "white",
  margin: "0 !important",
  border: "1px solid",
  borderColor: "gray.400",
  borderRadius: "base",
  dropShadow: "base",
  p: 2,
  position: "relative",
  display: "inline-flex",
  left: offsetCenter.x,
  top: offsetCenter.y,
  maxWidth: "400px",
});

const outerCirclePaint: CirclePaint = {
  "circle-color": "#0089c2",
  "circle-opacity": 0.5,
  "circle-radius": [
    "case",
    ["has", "point_count"],
    ["*", ["get", "point_count"], 8],
    8,
  ],
};

const innerCirclePaint: CirclePaint = {
  "circle-color": "#ffffff",
  "circle-stroke-color": "#0089c2",
  "circle-stroke-width": 1,
  "circle-radius": 4,
};

type PopupContentProps = {
  feature: any;
  strings: Required<DashboardMapStrings>;
};
const PopupContent = ({ feature, strings }: PopupContentProps) => {
  if (feature.properties.cluster) {
    return (
      <VStack {...popupPropsFactory(feature.offsetCenter)}>
        <Text>
          {strings.incidentCount({
            count: feature.properties.point_count,
          })}
        </Text>
      </VStack>
    );
  }
  return (
    <DashboardIncidentItem
      showSummaryOnly={true}
      incident={feature}
      {...popupPropsFactory(feature.offsetCenter)}
    />
  );
};

export type DashboardMapStrings = Partial<{
  /** Example: "32 incidents" */
  incidentCount: ValueToString<{ count: number }>;
}>;

const defaultStrings: Required<DashboardMapStrings> = {
  incidentCount: ({ count }) => `${count} incidents`,
};

export type DashboardMapProps = {
  /** Object of content strings and interpolators */
  strings?: DashboardMapStrings;
  /** List of incidents to display. Incidents with null geometries are not displayed */
  incidents: FeatureCollection<Point | null, IncidentProperties>;
  /**
   * The default viewport of the map
   */
  defaultViewport?: any;
  /**
   * Callback to handle changes originating from the map.
   * Provides the `lat` and `lon` position of the the selection
   */
  onChangeViewport?: (viewport: any) => void;
  /**
   * Mapbox token
   */
  mapboxToken?: string;
} & StackProps;

export const DashboardMap = forwardRef(({
  strings,
  mapboxToken,
  defaultViewport,
  onChangeViewport = () => { },
  incidents,
}: DashboardMapProps, ref) => {
  const [hoveredFeature, setHoveredFeature] = useState<any>(null);
  const [viewport, setViewport] = useState<any>(defaultViewport);
  const safeStrings: Required<DashboardMapStrings> = {
    ...defaultStrings,
    ...strings,
  };

  const physicalLocationIncidents = useMemo(
    () =>
      incidents.features.filter(
        (incident) => incident.geometry !== null
      ) as Feature<Point, IncidentProperties>[],
    [incidents]
  );

  const physicalLocationCollection = {
    type: "FeatureCollection" as const,
    features: physicalLocationIncidents,
  };

  const _onChangeViewport = useCallback(debounce(onChangeViewport, 250), []);

  const onViewportChange = useCallback(
    (vp: any) => {
      setViewport(vp);
      _onChangeViewport(vp);
    },
    [setViewport, _onChangeViewport]
  );

  const onMapHover = useCallback(
    (evt: MapEvent) => {
      if (evt.features) {
        const incident = evt.features.find((f) => f.layer.id === "incidents");
        if (incident) {
          if (!hoveredFeature || incident.id !== hoveredFeature.id) {
            setHoveredFeature({
              ...incident,
              lngLat: evt.lngLat,
              offsetCenter: evt.offsetCenter,
            });
          }
        } else {
          setHoveredFeature(null);
        }
      } else {
        setHoveredFeature(null);
      }
    },
    [hoveredFeature, setHoveredFeature]
  );

  const onMapInteraction = useCallback(
    (flags: any) => {
      if (flags?.isPanning) {
        setHoveredFeature(null);
      }
    },
    [setHoveredFeature]
  );

  return (
    <>
      <InteractiveMap
        {...viewport}
        ref={ref}
        width="100%"
        height="100%"
        onViewportChange={onViewportChange}
        mapboxApiAccessToken={mapboxToken}
        onHover={onMapHover}
        onInteractionStateChange={onMapInteraction}
        preserveDrawingBuffer
      >
        <Source type="geojson" data={physicalLocationCollection} cluster={true}>
          <Layer type="circle" paint={outerCirclePaint} id="incidents" />
          <Layer type="circle" paint={innerCirclePaint} />
          {hoveredFeature && (
            <PopupContent feature={hoveredFeature} strings={safeStrings} />
          )}
        </Source>
        <NavigationControl style={navControlStyle} />
      </InteractiveMap>
    </>
  );
});
