import {
  Box,
  Button,
  ButtonProps,
  ChakraProps,
  Heading,
  HeadingProps,
  HStack,
  IconButton,
  StackProps,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineChevronLeft } from "@react-icons/all-files/hi/HiOutlineChevronLeft";
import { FiLock } from "@react-icons/all-files/fi/FiLock";

import sticky from "_sx/sticky";
import controlButtonProps from "_sx/controlButtonProps";

export type MobileTopBarProps = {
  /**
   * The string to be displayed
   */
  title: string;
  /**
   * Passing in `onClickPrevious` will turn the title into a button
   */
  onClickPrevious?: () => void;
  /**
   * Callback for the always present logout button
   */
  onClickLogout: () => void;
};

const headingFontSize: ChakraProps["fontSize"] = "lg";

const stackProps: StackProps = {
  ...sticky,
  bg: "brand.darkBlue",
  p: 2,
  zIndex: 1,
};

const navigationButtonProps: ButtonProps = {
  variant: "link",
  color: "gray.50",
  leftIcon: <HiOutlineChevronLeft size="20px" />,
  fontSize: headingFontSize,
  fontWeight: "normal",
};

const headingProps: HeadingProps = {
  color: "gray.50",
  fontSize: headingFontSize,
  fontWeight: "normal",
  lineHeight: "normal",
  px: 2,
};

export const MobileTopBar = ({
  onClickPrevious,
  title,
  onClickLogout,
}: MobileTopBarProps) => {
  return (
    <HStack {...stackProps}>
      {onClickPrevious ? (
        <Button {...navigationButtonProps} onClick={onClickPrevious}>
          {title}
        </Button>
      ) : (
        <Heading {...headingProps}>{title}</Heading>
      )}
      <Box flex={1} />
      {onClickPrevious ? (
        <IconButton
          {...controlButtonProps}
          aria-label="logout"
          onClick={onClickLogout}
          color="brand.lightBlue"
          icon={<FiLock />}
        />
      ) : (
        <Button {...controlButtonProps} onClick={onClickLogout}>
          Logout
        </Button>
      )}
    </HStack>
  );
};
