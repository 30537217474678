import {
  Button,
  ButtonProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Heading,
  HeadingProps,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  StackProps,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback } from "react";

import { useIsFullsize } from "index";

const buttonPropsFactory = (isFullsize: boolean): ButtonProps => ({
  size: isFullsize ? "md" : "xl",
  colorScheme: "blue",
  variant: "solid",
});

const vstackProps: StackProps = {
  alignItems: "stretch",
  spacing: 8,
};

const headingProps: HeadingProps = {
  fontSize: "xl",
  textAlign: "center",
  fontWeight: "normal",
};

const buttonProps: ButtonProps = {
  size: "xl",
};

const ModalWrapper = (props: ModalProps) => (
  <Modal size="md" {...props}>
    <ModalOverlay />
    <ModalContent p={8}>{props.children}</ModalContent>
  </Modal>
);

const DrawerWrapper = (props: DrawerProps) => (
  <Drawer placement="bottom" {...props}>
    <DrawerOverlay />
    <DrawerContent p={4}>{props.children}</DrawerContent>
  </Drawer>
);

type NewReportTypeSelectionProps = Omit<NewReportButtonProps, "children">;

const NewReportTypeSelection = ({
  onClickOnlineIncident,
  onClickPhysicalIncident,
  onClickCancel,
  cancelText,
  onlineIncidentText,
  physicalIncidentText,
  prompt,
}: NewReportTypeSelectionProps) => (
  <VStack {...vstackProps}>
    <Heading {...headingProps}>{prompt}</Heading>
    <VStack alignItems="stretch">
      <Button
        {...buttonProps}
        onClick={onClickOnlineIncident}
        colorScheme="orange"
        variant="solidLight"
      >
        {onlineIncidentText}
      </Button>
      <Button
        {...buttonProps}
        onClick={onClickPhysicalIncident}
        colorScheme="orange"
        variant="solidLight"
      >
        {physicalIncidentText}
      </Button>
      <Button {...buttonProps} onClick={onClickCancel} colorScheme="gray">
        {cancelText}
      </Button>
    </VStack>
  </VStack>
);

export type NewReportButtonProps = {
  children: ReactNode;
  /**
   * The prompt text displayed to the user, i.e. "What type of incident do you want to create?"
   */
  prompt: string;
  /**
   * The text within the cancel button
   */
  cancelText: string;
  /**
   * The text within the physical incident button
   */
  physicalIncidentText: string;
  /**
   * The text within the online incident button
   */
  onlineIncidentText: string;
  /**
   * Callback for "Online incident" button
   */
  onClickOnlineIncident: () => void;
  /**
   * Callback for "Physical location incident" button
   */
  onClickPhysicalIncident: () => void;
  /**
   * Callback for the "cancel" button
   */
  onClickCancel?: () => void;
};

export const NewReportButton = ({
  children,
  onClickCancel,
  ...rest
}: NewReportButtonProps) => {
  const isFullsize = useIsFullsize();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const _onClickCancel = useCallback(() => {
    onClose();
    onClickCancel && onClickCancel();
  }, [onClose, onClickCancel]);

  return (
    <>
      <Button {...buttonPropsFactory(isFullsize)} onClick={onOpen}>
        {children}
      </Button>
      {isFullsize ? (
        <ModalWrapper isOpen={isOpen} onClose={onClose}>
          <NewReportTypeSelection {...rest} onClickCancel={_onClickCancel} />
        </ModalWrapper>
      ) : (
        <DrawerWrapper isOpen={isOpen} onClose={onClose}>
          <NewReportTypeSelection {...rest} onClickCancel={_onClickCancel} />
        </DrawerWrapper>
      )}
    </>
  );
};
