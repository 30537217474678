import {
  HStack,
  Heading,
  Button,
  StackProps,
  ButtonProps,
  HeadingProps,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import sticky from "_sx/sticky";

const stackProps: StackProps = {
  ...sticky,
  p: 2,
  background: "gray.100",
  fontSize: "16px",
  overflow: "hidden",
};

const headingProps: HeadingProps = {
  fontSize: "lg",
  fontWeight: "normal",
  flex: 1,
};

const editButtonProps: ButtonProps = {
  size: "sm",
  borderRadius: "base",
  px: 2,
  variant: "outline",
  borderColor: "gray.400",
  color: "gray.700",
};

export type EditableFormSectionHeaderProps = {
  children: ReactNode;
  /**
   * Text for the "edit" button
   */
  editButtonText?: string;
  /**
   * Callback for the "edit" button
   */
  onClickEdit: () => void;
};

export const EditableFormSectionHeader = ({
  children,
  onClickEdit,
  editButtonText = "Edit Section",
}: EditableFormSectionHeaderProps) => {
  return (
    <HStack {...stackProps}>
      <Heading {...headingProps}>{children}</Heading>
      <Button {...editButtonProps} onClickEdit={onClickEdit}>
        {editButtonText}
      </Button>
    </HStack>
  );
};
