import { Button, ButtonGroup } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { HStack } from "@chakra-ui/layout";
import React, { ReactNode } from "react";
import { FaSlidersH } from "react-icons/fa";

export type DashboardFilterControlBarString = Partial<{
  /** Text for the "Clear all filters" button*/
  clearButton: string;
}>;

const defaultStrings: Required<DashboardFilterControlBarString> = {
  clearButton: "Clear all filters",
};

export type DashboardFilterControlBarProps = {
  /**
   * Object of content strings and interpolators
   */
  strings?: DashboardFilterControlBarString;
  /**
   * Callback for the "Clear all filters" button
   */
  onClickClear?: () => void;
  children?: ReactNode;
};

export const DashboardFilterControlBar = ({
  strings,
  onClickClear = () => {},
  children = <></>,
}: DashboardFilterControlBarProps) => {
  const safeStrings: Required<DashboardFilterControlBarString> = {
    ...defaultStrings,
    ...strings,
  };
  return (
    <HStack bg="gray.100" px={4} py={2} spacing={4} boxShadow="xs">
      <Icon as={FaSlidersH} color="gray.400" />
      <ButtonGroup>{children}</ButtonGroup>
      <Button variant="link" size="sm" color="gray.700" onClick={onClickClear}>
        {safeStrings.clearButton}
      </Button>
    </HStack>
  );
};
