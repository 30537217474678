import { VStack, StackProps, StackDivider } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const stackProps: StackProps = {
  divider: <StackDivider borderColor="gray.100" />,
};

export type DashboardIncidentStackProps = {
  children: ReactNode;
};

export const DashboardIncidentStack = ({
  children,
}: DashboardIncidentStackProps) => {
  return <VStack {...stackProps}>{children}</VStack>;
};
