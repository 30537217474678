import {
  HStack,
  MenuList,
  VStack,
  Text,
  StackProps,
  MenuListProps,
  Box,
  StackDivider,
} from "@chakra-ui/react";
import React, { isValidElement, cloneElement, Children } from "react";

const menuProps: MenuListProps = {
  py: 0,
  boxShadow: "xl",
};

const stackProps: StackProps = {
  divider: <StackDivider borderColor="gray.300" />,
  alignItems: "flex-start",
};

const childProps: any = {
  flex: 1,
  p: 2,
  minWidth: "350px",
};

export type DividedMenuListProps = MenuListProps;

export const DividedMenuList = ({
  children,
  ...rest
}: DividedMenuListProps) => {
  const styledChildren = Children.map(
    children,
    (c) => isValidElement(c) && cloneElement(c, childProps)
  );
  return (
    <MenuList {...menuProps} {...rest}>
      <HStack {...stackProps}>{styledChildren}</HStack>
    </MenuList>
  );
};

export default DividedMenuList;
