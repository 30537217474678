import { ButtonProps } from "@chakra-ui/react";

const controlButtonProps: ButtonProps = {
  variant: "outline",
  color: "brand.lighterBlue",
  borderColor: "brand.lightBlue",
  fontWeight: "normal",
  size: "sm",
  px: 2,
  py: 4,
  borderRadius: "base",
  _notFirst: {
    ml: "-1px",
  },
  _hover: {
    bg: "brand.lightBlue",
    color: "brand.lightestBlue",
  },
  _active: {
    bg: "brand.lightBlue",
    color: "brand.lightestBlue",
  },
};

export default controlButtonProps;
