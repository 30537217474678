//ts-ignore
import { Button, HStack, VStack, Text, Icon, Spinner } from "@chakra-ui/react";
import { FeatureCollection, Point } from "geojson";
import React from "react";
import { FaMapMarker, FaMousePointer } from "react-icons/fa";
import { VscCloudDownload } from "react-icons/vsc";

import { IncidentProperties, ValueToString } from "types";

export type DashboardSummaryStrings = Partial<{
  /** Example: "Filtered Results: 32 incidents" */
  filteredResultCount: ValueToString<{ count: number }>;
  /** Example: "32 incidents" */
  unfilteredResultCount: ValueToString<{ count: number }>;
  /** Example: "32 total" */
  totalResultCount: ValueToString<{ count: number }>;
  /** Example: "16 incidents tied to a location" */
  locatedResultCount: ValueToString<{ count: number }>;
  /** Example: "16 online-only incidents" */
  onlineResultCount: ValueToString<{ count: number }>;
  /** Example: "Export" */
  exportText: string;
  /** Example: "Download" */
  downloadText: string;
  /** Example: ".csv file" where the filetype is provided*/
  exportFileType: ValueToString<{ fileType: string }>;
}>;

const defaultStrings: Required<DashboardSummaryStrings> = {
  filteredResultCount: ({ count }) => `Filtered Results: ${count} incidents`,
  unfilteredResultCount: ({ count }) => `${count} incidents`,
  totalResultCount: ({ count }) => `${count} total`,
  locatedResultCount: ({ count }) => `${count} incidents tied to a location`,
  onlineResultCount: ({ count }) => `${count} online-only incidents`,
  exportText: "Export",
  downloadText: "Download",
  exportFileType: ({ fileType }) => `${fileType} file`,
};

export type DashboardSummaryBarProps = {
  /** Object of content strings and interpolators */
  strings?: DashboardSummaryStrings;
  /**
   * A feature-collection of incident reports.
   * Geometry can be null.
   * Should be filtered before being provided to this component
   */
  incidents: FeatureCollection<Point | null, IncidentProperties>;
  /**
   * The total number of incidents before any filtering
   */
  totalIncidentCount: number;
  /**
   * A flag to indicate that some filters are active
   */
  isFiltered?: boolean;
  /**
   * A flag to indicate that the PDF is being generated
   */
  isGeneratingPdf?: boolean;
  /**
   * A callback to handle clicks on the "Export" button
   */
  onClickExport?: (
    incidents: FeatureCollection<Point | null, IncidentProperties>
  ) => void;
  /**
   * Form id for physical incidents
   */
  physicalFormId: number;
  /**
   * Form id for online incidents
   */
  onlineFormId: number;
};

export const DashboardSummaryBar = ({
  strings = defaultStrings,
  incidents,
  totalIncidentCount,
  isFiltered = false,
  isGeneratingPdf = false,
  onClickExport,
  physicalFormId,
  onlineFormId,
}: DashboardSummaryBarProps) => {
  const safeStrings: Required<DashboardSummaryStrings> = {
    ...defaultStrings,
    ...strings,
  };
  const withLocationCount = incidents.features.filter(
    i => i.properties.form === physicalFormId
  ).length;
  const withoutLocationCount = incidents.features.filter(
    i => i.properties.form === onlineFormId
  ).length;

  return (
    <HStack boxShadow="base" pl={4} pr={2} py={2} zIndex="1">
      <VStack flex="1" alignItems="flex-start" spacing={1}>
        <HStack fontSize="17px">
          <Text fontWeight="bold">
            {isFiltered
              ? safeStrings.filteredResultCount({
                count: incidents.features.length,
              })
              : safeStrings.unfilteredResultCount({
                count: incidents.features.length,
              })}
          </Text>
          {isFiltered && (
            <Text color="gray.700">
              ({safeStrings.totalResultCount({ count: totalIncidentCount })})
            </Text>
          )}
        </HStack>
        <HStack fontSize="15px">
          <HStack alignItems="center">
            <Icon as={FaMapMarker} fontSize="13px" color="cyan.500" />
            <Text>
              {safeStrings.locatedResultCount({ count: withLocationCount })}
            </Text>
          </HStack>
          <HStack>
            <Icon as={FaMousePointer} fontSize="13px" color="cyan.500" />
            <Text>
              {safeStrings.onlineResultCount({ count: withoutLocationCount })}
            </Text>
          </HStack>
        </HStack>
      </VStack>
      {!!onClickExport && (
        <HStack>
          <Button
            colorScheme="orange"
            variant="solidLight"
            borderRadius="base"
            height="auto"
            maxH={62}
            py={3}
            px={4}
            leftIcon={
              <Icon as={VscCloudDownload} fontSize="36" color="gray.500" />
            }
            // @ts-ignore
            onClick={e => onClickExport('csv', e)}
          >
            <VStack spacing={0}>
              <Text fontWeight="bold">{safeStrings.exportText}</Text>
              <Text>
                {safeStrings.exportFileType({
                  fileType: ".csv",
                })}
              </Text>
            </VStack>
          </Button>
          <Button
            colorScheme="orange"
            variant="solidLight"
            borderRadius="base"
            height="auto"
            maxH={62}
            py={3}
            px={4}
            isLoading={isGeneratingPdf}
            spinner={<Spinner size={'md'} />}
            leftIcon={
              <Icon as={VscCloudDownload} fontSize="36" color="gray.500" />
            }
            // @ts-ignore
            onClick={e => onClickExport('pdf', e)}
          >
            <VStack spacing={0}>
              <Text fontWeight="bold">{safeStrings.downloadText}</Text>
              <Text>
                {safeStrings.exportFileType({
                  fileType: ".pdf",
                })}
              </Text>
            </VStack>
          </Button>
        </HStack>
      )}
    </HStack>
  );
};
