import React, { ReactNode } from "react";
import { Global, css } from "@emotion/core";
import "focus-visible/dist/focus-visible";
import { ChakraProvider, ChakraTheme, extendTheme } from "@chakra-ui/react";

import theme from "theme";

/* eslint-disable-next-line */
import NotoArabicRegularWoff2 from "../../fonts/NotoSansArabicUI-Regular.woff2";
/* eslint-disable-next-line */
import NotoArabicSemiBoldWoff2 from "../../fonts/NotoSansArabicUI-SemiBold.woff2";

const GlobalStyles = css`
  /* 
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
  @font-face {
    font-family: "NotoSansArabicUI";
    src: url(${NotoArabicRegularWoff2}) format("woff2");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "NotoSansArabicUI";
    src: url(${NotoArabicSemiBoldWoff2}) format("woff2");
    font-weight: 600;
    font-style: normal;
  }
`;

export type AppThemeProviderProps = {
  children: ReactNode;
  /**
   * Additional theme values to merge into the provided theme
   */
  theme?: Partial<ChakraTheme>;
};

export const AppThemeProvider = ({
  children,
  theme: userTheme = {},
}: AppThemeProviderProps) => {
  const mergedTheme = extendTheme(theme, userTheme);

  return (
    <ChakraProvider theme={mergedTheme}>
      <Global styles={GlobalStyles} />
      {children}
    </ChakraProvider>
  );
};
