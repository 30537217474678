import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  StackProps,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { useIsFullsize } from "index";
import { ResponsiveRender } from "types";

const outerContainerPropsFactory = (isFullsize: boolean): FlexProps => ({
  minHeight: "100vh",
  width: "100%",
  bg: isFullsize ? "gray.100" : "white",
});

const innerContainerProps: BoxProps = {
  flex: "1",
  maxWidth: "1020px",
  margin: "0 auto",
};

const hStackprops: StackProps = {
  flex: 1,
  spacing: 8,
};

const sidebarContainerPropsFactory = (isFullsize: boolean): FlexProps =>
  isFullsize
    ? {
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "stretch",
        width: "265px",
        ml: 4,
      }
    : {
        flexDirection: "column",
        alignSelf: "stretch",
        alignItems: "stretch",
        flex: 1,
      };

const headerContainerPropsFactory = (isFullsize: boolean): BoxProps =>
  isFullsize ? { mt: 6, mb: 12 } : { mb: 6, ml: 4, pt: 6 };

const listsContainerPropsFactory = (isFullsize: boolean): BoxProps =>
  isFullsize ? { mx: -4, mb: 8 } : { flex: 1, alignSelf: "stretch" };

const controlsContainerPropsFactory = (isFullsize: boolean): FlexProps =>
  isFullsize ? {} : { alignSelf: "stretch", p: 2 };

const detailContainerProps: FlexProps = {
  bg: "white",
  alignSelf: "stretch",
  flex: "1",
};

type DetailListProps = {
  /**
   * Content to render in the topbar area
   */
  renderTopBar?: ResponsiveRender;
  /**
   * Content to render in the header area
   */
  renderHeader: ResponsiveRender;
  /**
   * Content to render in the list area
   */
  renderLists: ResponsiveRender;
  /**
   * Content to render in the controls area
   */
  renderControls: ResponsiveRender;
  /**
   * Content to render in the detail area
   */
  renderDetails: ResponsiveRender;
};

export const DetailList = ({
  renderTopBar = () => <></>,
  renderHeader,
  renderLists,
  renderControls,
  renderDetails,
}: DetailListProps) => {
  const isFullsize = useIsFullsize();
  return (
    <>
      <Flex {...outerContainerPropsFactory(isFullsize)}>
        <Flex {...innerContainerProps}>
          <HStack {...hStackprops}>
            <Flex {...sidebarContainerPropsFactory(isFullsize)}>
              {renderTopBar(isFullsize)}
              <Box {...headerContainerPropsFactory(isFullsize)}>
                {renderHeader(isFullsize)}
              </Box>

              <Box {...listsContainerPropsFactory(isFullsize)}>
                {renderLists(isFullsize)}
              </Box>
              <Box {...controlsContainerPropsFactory(isFullsize)}>
                {renderControls(isFullsize)}
              </Box>
            </Flex>
            {isFullsize ? (
              <Flex {...detailContainerProps}>{renderDetails(isFullsize)}</Flex>
            ) : (
              renderDetails(isFullsize)
            )}
          </HStack>
        </Flex>
      </Flex>
    </>
  );
};

type ResponsiveDetailContainerProps = {
  children: ReactNode;
};

export const ResponsiveDetailContainer = ({
  children,
}: ResponsiveDetailContainerProps) => {
  const isFullsize = useIsFullsize();

  return isFullsize ? (
    <>{children}</>
  ) : (
    <Modal isOpen={true} onClose={() => {}} size="full">
      <ModalOverlay />
      <ModalContent borderRadius="0">{children}</ModalContent>
    </Modal>
  );
};
