import {
  Box,
  BoxProps,
  forwardRef,
  FormControl,
  FormControlProps,
  StackProps,
  VStack,
  FormLabel,
  FormLabelProps,
  HelpTextProps,
  FormHelperText,
  HStack,
} from "@chakra-ui/react";
import React, { cloneElement, ReactElement } from "react";

const outerStackProps: StackProps = {
  spacing: 4,
};

const modificationIndicatorPropsFactory = (isModified: boolean): BoxProps => ({
  alignSelf: "stretch",
  width: "6px",
  bg: isModified ? "orange.400" : "transparent",
});

const innerStackProps: StackProps = {
  width: "100%",
  alignItems: "flex-start",
  spacing: 3,
};

const labelStackProps: StackProps = {
  width: "100%",
  alignItems: "flex-start",
  spacing: 2,
};

const labelProps: FormLabelProps = {
  fontSize: "md",
  color: "gray.700",
  lineHeight: 1,
};

const descriptionProps: HelpTextProps = {
  fontSize: "sm",
  color: "gray.600",
  lineHeight: 1,
};

export type LabeledFormControlProps = FormControlProps & {
  /**
   * The field label to display
   */
  label: string;
  /**
   * An optional description will be displayed below the label
   */
  description?: string;
  /**
   * Display a modification indicator on the field
   */
  isModified?: boolean;
  /**
   * Indicate that the field is in read-only mode
   */
  isReadOnly?: boolean;
  children: ReactElement;
};

export const LabeledFormControl = forwardRef<LabeledFormControlProps, "div">(
  (
    { label, description, isModified = false, isReadOnly = false, ...rest },
    ref
  ) => {
    const childProps = isModified ? { color: "orange.700" } : {};
    return (
      <HStack {...outerStackProps}>
        {!!isReadOnly && (
          <Box {...modificationIndicatorPropsFactory(isModified)} />
        )}
        <FormControl ref={ref} isReadOnly={isReadOnly} {...rest}>
          <VStack {...innerStackProps}>
            <VStack {...labelStackProps}>
              <FormLabel {...labelProps}>{label}</FormLabel>
              {!!description && !isReadOnly && (
                <FormHelperText {...descriptionProps}>
                  {description}
                </FormHelperText>
              )}
            </VStack>
            {cloneElement(rest.children, childProps)}
          </VStack>
        </FormControl>
      </HStack>
    );
  }
);
