import {
  Button,
  ButtonProps,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerProps,
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  StackProps,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { ReactNode, useCallback, useMemo } from "react";

import { useIsFullsize } from "index";

const buttonPropsFactory = (isFullsize: boolean): ButtonProps => ({
  size: isFullsize ? "md" : "xl",
  variant: "solid",
});

const modalProps: Partial<ModalProps> = {
  size: "md",
};

const containerProps: FlexProps = {
  width: "100%",
};

const stackProps: StackProps = {
  alignItems: "stretch",
  spacing: 6,
  width: "100%",
  bg: "white",
};

const headingProps: HeadingProps = {
  fontSize: "xl",
  textAlign: "center",
  px: "17.5%",
  fontWeight: "normal",
};

const buttonProps: ButtonProps = {
  size: "lg",
};

const ModalWrapper = (props: ModalProps) => (
  <Modal {...modalProps} {...props}>
    <ModalOverlay />
    <ModalContent p={8}>{props.children}</ModalContent>
  </Modal>
);

const DrawerWrapper = (props: DrawerProps) => (
  <Drawer placement="bottom" {...props}>
    <DrawerOverlay />
    <DrawerContent p={4}>{props.children}</DrawerContent>
  </Drawer>
);

export type ConfirmButtonProps = {
  children: ReactNode;
  /**
   * The message displayed in the confirmation dialog
   */
  confirmationPrompt: string;
  /**
   * Callback triggered when the user clicks the confirmation button
   */
  onConfirm: () => void;
  /**
   * Callback triggered when the user clicks the "Cancel" button
   */
  onCancel?: () => void;
  /**
   * Label for cancel button
   */
  cancelLabel?: string;
} & ButtonProps;

const ConfirmDialog = ({
  children,
  onConfirm,
  confirmationPrompt,
  colorScheme,
  onCancel = () => ({}),
  cancelLabel,
}: ConfirmButtonProps) => {
  return (
    <Flex {...containerProps}>
      <VStack {...stackProps}>
        <Heading {...headingProps}>{confirmationPrompt}</Heading>
        <VStack alignItems="stretch">
          <Button
            {...buttonProps}
            onClick={onConfirm}
            colorScheme={colorScheme}
          >
            {children}
          </Button>
          <Button {...buttonProps} colorScheme="gray" onClick={onCancel}>
            {cancelLabel}
          </Button>
        </VStack>
      </VStack>
    </Flex>
  );
};

export const ConfirmButton = ({
  children,
  onConfirm,
  onCancel,
  confirmationPrompt,
  colorScheme = "cyan",
  cancelLabel = "Cancel",
  ...rest
}: ConfirmButtonProps) => {
  const isFullsize = useIsFullsize();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const _onCancel = useCallback(() => {
    onClose();
    !!onCancel && onCancel();
  }, [onClose, onCancel]);

  const _onConfirm = useCallback(() => {
    onClose();
    onConfirm();
  }, [onClose, onConfirm]);

  const memoizedDialog = useMemo(
    () => (
      <ConfirmDialog
        confirmationPrompt={confirmationPrompt}
        onConfirm={_onConfirm}
        onCancel={_onCancel}
        colorScheme={colorScheme}
        cancelLabel={cancelLabel}
      >
        {children}
      </ConfirmDialog>
    ),
    [confirmationPrompt, onConfirm, onCancel, children, colorScheme]
  );

  return (
    <>
      <Button
        {...buttonPropsFactory(isFullsize)}
        onClick={onOpen}
        colorScheme={colorScheme}
        {...rest}
      >
        {children}
      </Button>
      {isFullsize ? (
        <ModalWrapper isOpen={isOpen} onClose={_onCancel}>
          {memoizedDialog}
        </ModalWrapper>
      ) : (
        <DrawerWrapper isOpen={isOpen} onClose={_onCancel}>
          {memoizedDialog}
        </DrawerWrapper>
      )}
    </>
  );
};
