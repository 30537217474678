import { StackProps, VStack, HStack, Box, BoxProps } from "@chakra-ui/react";
import React from "react";

import { useIsFullsize } from "index";
import { ResponsiveRender } from "types";

const containerProps: StackProps = {
  alignItems: "stretch",
  spacing: 0,
  flex: 1,
  overflow: "hidden",
};

const hStackProps: StackProps = {
  flex: 1,
  spacing: 0,
  alignItems: "stretch",
  overflow: "hidden",
};

const listContainerProps: BoxProps = {
  width: "500px",
  borderRight: "1px solid",
  borderColor: "gray.100",
  overflowY: "auto",
};

const mapContainerProps: BoxProps = {
  flex: 1,
};

export type DashboardProps = {
  /**
   * Render slot for the topmost area. Used to render the `DashboardFilterControlBar`
   */
  renderFilterControlBar: ResponsiveRender;
  /**
   * Render slot for the second topmost. Used to render the `DashboardSummaryBar`
   */
  renderSummaryBar: ResponsiveRender;
  /**
   * Render slot for the second topmost. Used to render a `DashboardIncidentStack`
   */
  renderIncidentList: ResponsiveRender;
  /**
   * Render slot for the second topmost. Used to render a `DashboardMap`
   */
  renderMap: ResponsiveRender;
};

export const Dashboard = ({
  renderFilterControlBar,
  renderSummaryBar,
  renderIncidentList,
  renderMap,
}: DashboardProps) => {
  const isFullsize = useIsFullsize();

  return (
    <VStack {...containerProps}>
      {renderFilterControlBar(isFullsize)}
      {renderSummaryBar(isFullsize)}
      <HStack {...hStackProps}>
        <Box {...listContainerProps}>{renderIncidentList(isFullsize)}</Box>
        <Box {...mapContainerProps}>{renderMap(isFullsize)}</Box>
      </HStack>
    </VStack>
  );
};
