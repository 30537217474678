import {
  Badge,
  Box,
  Button,
  ButtonProps,
  Flex,
  Heading,
  HStack,
  StackProps,
  Text,
  TextProps,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { intlFormat } from "date-fns";

import { MiddotDivider, useIsFullsize } from "index";

const buttonPropsFactory = (isFullsize: boolean): ButtonProps => {
  const common: ButtonProps = {
    justifyContent: "flex-start",
    height: "auto",
    whiteSpace: "normal",
    p: 2,
  };
  return isFullsize
    ? { ...common, variant: "ghost" }
    : { ...common, variant: "outline" };
};

const containerProps: StackProps = {
  spacing: 2,
  height: "auto",
  whiteSpace: "normal",
  alignItems: "stretch",
};

const titleProps: TextProps = {
  fontSize: "lg",
  textAlign: "left",
  fontWeight: "semibold",
};

const innerStackProps: StackProps = {
  spacing: 1,
  alignItems: "flex-start",
  color: "gray.600",
};

const modifiedTagProps: TextProps = {
  color: "orange.700",
  fontWeight: "normal",
  textAlign: "left",
};

export type ReportItemProps = {
  /**
   * The report title to display
   */
  title: string;
  /**
   * The ISO date-string creation time
   */
  createdAt: string;
  /**
   * The ISO date-string incident date
   */
  incidentDate: string;
  /**
   * The string time of day, e.g. "Afternoon" of the incident
   */
  incidentTimeOfDay: string;
  /**
   * The ISO date-string modified time
   */
  modifiedAt: string;
  /**
   * If provided, the item will display the user
   */
  createdBy?: string | number;
  /**
   * If provuided, the item will display the location
   */
  locationName?: string;
  /**
   * Text to use for element on mobile viewports
   */
  shortLabel?: string;
  /**
   * Text to use for element on desktop viewports
   */
  fullLabel?: string;
} & ButtonProps;

type DateDisplayProps = Pick<
  ReportItemProps,
  "incidentDate" | "incidentTimeOfDay"
>;
const DateDisplay = ({ incidentDate }: DateDisplayProps) => {
  const reportDate = useMemo(
    () =>
      intlFormat(new Date(incidentDate), {
        month: "short",
        day: "numeric",
        year: "numeric",
      }),
    [incidentDate]
  );

  return <Text>{reportDate}</Text>;
};

type ModifiedBadgeProps = Pick<ReportItemProps, "createdAt" | "modifiedAt" | "shortLabel" | "fullLabel">;
const ModifiedBadge = ({ createdAt, modifiedAt, shortLabel, fullLabel }: ModifiedBadgeProps) => {
  const isFullsize = useIsFullsize();
  const isModified = useMemo(
    () => createdAt !== modifiedAt,
    [createdAt, modifiedAt]
  );
  return isModified ? (
    isFullsize ? (
      <Badge
        colorScheme="orange"
        fontSize="14px"
        variant="subtle"
        alignSelf="flex-start"
      >
        {shortLabel}
      </Badge>
    ) : (
      <Text {...modifiedTagProps}>{fullLabel}</Text>
    )
  ) : (
    <></>
  );
};

export const ReportItem = ({
  title,
  createdAt,
  modifiedAt,
  createdBy,
  incidentDate,
  incidentTimeOfDay,
  locationName,
  shortLabel,
  fullLabel,
  ...rest
}: ReportItemProps) => {
  const isFullsize = useIsFullsize();
  const buttonProps = useMemo(
    () => buttonPropsFactory(isFullsize),
    [isFullsize]
  );
  return (
    <Button {...buttonProps} {...rest}>
      <VStack {...containerProps}>
        <Text {...titleProps}>{title}</Text>
        <VStack {...innerStackProps}>
          {isFullsize ? (
            <HStack spacing={4}>
              <HStack spacing={2} divider={<MiddotDivider />}>
                <DateDisplay
                  incidentDate={incidentDate}
                  incidentTimeOfDay={incidentTimeOfDay}
                />
                <Text>{incidentTimeOfDay}</Text>
                {!!locationName && <Text>{locationName}</Text>}
              </HStack>
              <ModifiedBadge
                createdAt={createdAt}
                modifiedAt={modifiedAt}
                shortLabel={shortLabel}
                fullLabel={fullLabel}
              />
            </HStack>
          ) : (
            <>
              <HStack spacing={2} divider={<MiddotDivider />}>
                <DateDisplay
                  incidentDate={incidentDate}
                  incidentTimeOfDay={incidentTimeOfDay}
                />
                <Text>{incidentTimeOfDay}</Text>
              </HStack>
              <ModifiedBadge
                createdAt={createdAt}
                modifiedAt={modifiedAt}
                shortLabel={shortLabel}
                fullLabel={fullLabel}
              />
            </>
          )}
        </VStack>
      </VStack>
    </Button>
  );
};
