import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  HStack,
  StackProps,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import sticky from "_sx/sticky";
import controlButtonProps from "_sx/controlButtonProps";

const stackProps: StackProps = {
  ...sticky,
  bg: "brand.darkBlue",
  py: 2,
  px: 4,
  pr: 2,
  zIndex: 1,
  alignItems: "center",
  spacing: 4,
};

const childContainerProps: StackProps = {
  spacing: 4,
};

const labelProps: BoxProps = {
  fontWeight: "bold",
  fontSize: "md",
  color: "white",
};

export type LanguageOption = {
  label: string;
  value: string;
};

export type FullsizeTopBarProps = {
  /**
   * Content that should be displayed in the logo area
   */
  logoAs: ReactNode;
  /**
   * Children are expected to be `TopBarNavigationButtons`
   */
  children: ReactNode;
  /**
   * A list of language options
   */
  languages: LanguageOption[];
  /**
   * Callback for the always present logout button
   */
  onClickLogout: () => void;
  /**
   * Callback for the language change button
   */
  onLanguageChange: (language: string) => void;
  /**
   * Currently active language value -- should match the provided language options
   */
  languageValue: string;
  /**
   * The text displayed inside of the logout button
   */
  logoutButtonText: string;
  /**
   * A toggle to show or hide the logout button
   */
  showLogout?: boolean;
};

export const FullsizeTopBar = ({
  logoAs,
  children,
  onClickLogout,
  languages,
  onLanguageChange,
  languageValue,
  logoutButtonText,
  showLogout = true,
}: FullsizeTopBarProps) => {
  return (
    <HStack {...stackProps}>
      <Box {...labelProps}>{logoAs}</Box>
      <Box flex={1} />
      <HStack {...childContainerProps}>{children}</HStack>
      {showLogout && (
        <Button {...controlButtonProps} onClick={onClickLogout}>
          {logoutButtonText}
        </Button>
      )}
      <ButtonGroup isAttached variant="outline">
        {languages.map((l, i) => (
          <Button
            {...controlButtonProps}
            key={l.value}
            onClick={() => onLanguageChange(l.value)}
            isActive={l.value === languageValue}
          >
            {l.label}
          </Button>
        ))}
      </ButtonGroup>
    </HStack>
  );
};
