import { Box, RadioProps, Radio } from "@chakra-ui/react";
import React from "react";

export type RadioButtonProps = RadioProps;

export const RadioButton = ({
  colorScheme = "gray",
  fontSize = "lg",
  ...rest
}: RadioButtonProps) => {
  return (
    <Box
      fontSize={fontSize}
      borderRadius="md"
      border="2px solid"
      borderColor="gray.200"
      px={4}
      py={2}
      width="100%"
    >
      <Radio width="100%" colorScheme={colorScheme} {...rest} />
    </Box>
  );
};
