const text = {
  variants: {
    fieldValue: {
      fontSize: "lg",
      color: "black",
    },
  },
};

export default text;
