import { transparentize, mode, darken } from "@chakra-ui/theme-tools";

const variantSubtle = (props: any) => {
  const { colorScheme: c, theme } = props;
  const darkBg = transparentize(`${c}.200`, 0.16)(theme);
  return {
    bg: mode(`${c}.100`, darkBg)(props),
    color: mode(`${c}.700`, `${c}.200`)(props),
  };
};

const badge = {
  baseStyle: {
    fontWeight: "normal",
    px: "0.5em",
    py: "0.4em",
    textTransform: "none",
  },
  variants: {
    subtle: variantSubtle,
  },
};

export default badge;
