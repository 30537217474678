import {
  HStack,
  StackProps,
  Text,
  Box,
  BoxProps,
  Icon,
  IconButton,
  IconButtonProps,
  forwardRef,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { HiPlus } from "@react-icons/all-files/hi/HiPlus";
import { HiX } from "@react-icons/all-files/hi/HiX";
import { HiChevronUp } from "@react-icons/all-files/hi/HiChevronUp";

const containerPropsFactory = (
  isActive: boolean,
  isFiltering: boolean
): StackProps => {
  const baseProps: StackProps = {
    display: "inline-flex",
    border: "1px solid",
    borderRadius: "base",
    borderColor: "gray.300",
    fontSize: "15px",
    pl: 3,
    pr: 1.5,
    py: "5px",
    lineHeight: 1,
    cursor: "pointer",
    _hover: {
      borderColor: "cyan.600",
      backgroundColor: "gray.100",
    },
  };

  const isActiveProps: StackProps = {
    borderColor: "cyan.600",
    outline: "1px solid",
    outlineColor: "cyan.600",
  };

  const isFilteringProps: StackProps = {
    background: "cyan.600",
    color: "white",
    borderColor: "cyan.600",
    _hover: {
      backgroundColor: "cyan.700",
    },
  };

  const appliedProps = isActive
    ? isActiveProps
    : isFiltering
    ? isFilteringProps
    : {};

  return {
    ...baseProps,
    ...appliedProps,
  };
};

const clearButtonProps: IconButtonProps = {
  "aria-label": "Remove filter",
  minWidth: "20px",
  height: "20px",
  width: "20px",
  borderRadius: "base",
  background: "cyan.800",
  color: "gray.100",
  icon: <HiX />,
  _hover: {
    background: "cyan.900",
  },
  _active: {
    background: "cyan.700",
  },
};

const iconContainerProps: BoxProps = {
  height: "20px",
  width: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export type FilterButtonProps = {
  /**
   * isActive is used to switch the button styling for when the dropdown menu is open
   */
  isActive?: boolean;
  /**
   * isFiltering is used to alter the button styling for when any filters within the dropdown are active
   */
  isFiltering?: boolean;
  /**
   * onClickClear is a callback that is triggered when the clear button is clicked
   */
  onClickClear: () => void;
} & StackProps;

/**
 * `FilterButton` is intended to be used as the trigger element for a chakra overlay element
 * such as a `Menu` or `Popover`. It contains a built-in button intended to be used to clear all filters
 * that are housed in the corresponding dropdown
 */
export const FilterButton = forwardRef<FilterButtonProps, "div">(
  (
    { isActive = false, isFiltering = false, onClickClear, children, ...rest },
    ref
  ) => {
    const _onClickClear = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        onClickClear();
      },
      [onClickClear]
    );
    return (
      <HStack
        {...containerPropsFactory(isActive, isFiltering)}
        {...rest}
        ref={ref}
      >
        <Text as="span">{children}</Text>
        {isActive ? (
          <Box {...iconContainerProps}>
            <Icon as={HiChevronUp} color="gray.400" fontSize="16px" />
          </Box>
        ) : isFiltering ? (
          <IconButton {...clearButtonProps} onClick={_onClickClear} />
        ) : (
          <Box {...iconContainerProps}>
            <Icon as={HiPlus} color="gray.400" fontSize="16px" />
          </Box>
        )}
      </HStack>
    );
  }
);
