import { getColor } from "@chakra-ui/theme-tools";

import { variantOutline } from "./input";

const textarea = {
  variants: {
    outline: (props: any) => variantOutline(props).field ?? {},
    lined: (props: any) => ({
      ...(variantOutline(props).field ?? {}),
      backgroundPosition: "0 0.2em",
      fontSize: "lg",
      lineHeight: "1.638em",
      backgroundImage: `repeating-linear-gradient(transparent 0em, transparent 1.55em, ${getColor(
        props.theme,
        "gray.200"
      )} 1.635em)`,
      backgroundAttachment: "local",
      color: "inherit",
      position: "relative",
      width: "100%",
    }),
  },
};

export default textarea;
