import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const buttonProps: ButtonProps = {
  variant: "link",
  color: "white",
  fontWeight: "bold",
  _active: {
    color: "white",
    textDecoration: "underline",
  },
};

export const TopBarNavigationButton = forwardRef<ButtonProps, "button">(
  (props: ButtonProps, ref) => {
    return <Button {...buttonProps} {...props} ref={ref} />;
  }
);
