import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  HStack,
  Icon,
  StackProps,
  forwardRef,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { HiOutlineChevronRight } from "@react-icons/all-files/hi/HiOutlineChevronRight";

import { useIsFullsize } from "index";

export type NavigationButtonProps = {
  count?: string | number;
} & ButtonProps;

const buttonPropsFactory = (isFullsize: boolean): ButtonProps =>
  isFullsize
    ? {
        borderRadius: "lg",
        isFullWidth: false,
        variant: "ghost",
      }
    : {
        borderRadius: 0,
        isFullWidth: true,
        fontSize: "xl",
        fontWeight: "normal",
        p: 4,
        height: "auto",
      };

const stackProps: StackProps = {
  width: "100%",
  alignItems: "center",
};

const labelProps: BoxProps = {
  flex: "1",
  textAlign: "left",
  fontSize: "lg",
};

const countProps: BoxProps = {
  fontSize: "md",
};

/**
 * The `NavigationButton` component is responsive. Examples on this page will respond to the size of the browser window.
 */
export const NavigationButton = forwardRef<NavigationButtonProps, "button">(
  ({ count, children, ...rest }, ref) => {
    const isFullsize = useIsFullsize();

    const buttonProps = useMemo(
      () => buttonPropsFactory(isFullsize),
      [isFullsize]
    );

    return (
      <Button {...buttonProps} {...rest} ref={ref}>
        <HStack {...stackProps}>
          <Box {...labelProps}>{children}</Box>
          {count && <Box {...countProps}>{count}</Box>}
          {!isFullsize && (
            <Icon as={HiOutlineChevronRight} fontSize="24px" color="gray.400" />
          )}
        </HStack>
      </Button>
    );
  }
);
