import { extendTheme } from "@chakra-ui/react";

import button from "theme/button";
import "@fontsource/noto-sans";
import badge from "theme/badge";
import textarea from "theme/textarea";
import input from "theme/input";
import form from "theme/form";
import formLabel from "theme/formLabel";
import text from "theme/text";
import select from "theme/select";
import numberInput from "theme/numberInput";

const theme = extendTheme({
  fonts: {
    body: "'Noto Sans', 'NotoSansArabicUI', -ui, sans-serif",
    heading: "'Noto Sans', system-ui, sans-serif",
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.9375rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.375rem",
    "3xl": "1.5rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  },
  colors: {
    brand: {
      darkBlue: "#1e376c",
      lightBlue: "#62749c",
      lighterBlue: "#c7d9ff",
      lightestBlue: "#d6e3ff",
      cyan: "#0c9ad6",
      gold: "#f3dab2",
    },
    gray: {
      "50": "#F2F2F3",
      "100": "#EAEAEC",
      "200": "#C3C3C6",
      "300": "#ABABB0",
      "400": "#93939A",
      "500": "#7C7C83",
      "600": "#636369",
      "700": "#4A4A4F",
      "800": "#313135",
      "900": "#19191A",
    },
    cyan: {
      "50": "#E7F7FE",
      "100": "#BBE8FB",
      "200": "#90DAF9",
      "300": "#64CBF6",
      "400": "#39BCF4",
      "500": "#0c9ad6",
      "600": "#0B8BC1",
      "700": "#086891",
      "800": "#054661",
      "900": "#032330",
    },
    orange: {
      "50": "#FCF4E9",
      "100": "#F5E1C1",
      "200": "#f3dab2",
      "300": "#E9BB72",
      "400": "#E3A84A",
      "500": "#DD9522",
      "600": "#B0771C",
      "700": "#845915",
      "800": "#583C0E",
      "900": "#2C1E07",
    },
  },
  components: {
    Button: button,
    Badge: badge,
    Input: input,
    Textarea: textarea,
    Form: form,
    FormLabel: formLabel,
    NumberInput: numberInput,
    Text: text,
    Select: select,
  },
  direction: document?.dir || "ltr",
});

export default theme;
