import { mode } from "@chakra-ui/theme-tools";
import { formAnatomy as parts } from "@chakra-ui/anatomy";

import type {
  SystemStyleFunction,
  PartsStyleFunction,
} from "@chakra-ui/theme-tools";

const baseStyleHelperText: SystemStyleFunction = (props) => {
  return {
    mt: 0,
    color: mode("gray.700", "whiteAlpha.300")(props),
    lineHeight: "normal",
    fontSize: "sm",
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  helperText: baseStyleHelperText(props),
});

export default {
  parts: parts.keys,
  baseStyle,
};
